








































































































































































































































































































































































































































































@import '~@/assets/styles/helpers/_variables.scss';
.container {
  position: relative;

  &-loading {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 99;
    background-color: rgba(255, 255, 255, .6);
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.wrapper {
  max-width: 1250px;
  background-color: #FFF;
  margin: 0 auto;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .3);
  padding: 10px !important;

  &-titulo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 15px;

    h1, div {
      width: 200px;
    }
  }

  &-subtitulo {
    display: flex;
    position: relative;
    align-items: flex-end;
    position: relative;
    width: 100%;
    margin-bottom: 15px;
    &-total{
      position: absolute;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 0px;
      padding: 10px;
      max-width: 20vw;
      background:#008acf;
      text-align: center;
      font-weight: bold;
      color: white
    }
    .v-input{
      margin-top: 0;
      width: 100%;
    }
    h2.titulo {
      color: #4a5275;
      text-transform: none;
      font-size: 20px;
    }

    p {
      margin-bottom: 0;
      margin-left: 20px;
      font-size: 14px;
    }
  }
}

.box-table {
}
